<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row>
          <v-col md="2.5">
            <v-text-field
              v-model="title"
              :label="$t('search by homework title')"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="2.5">
            <v-text-field
              v-model="descirption"
              :label="$t('search by descirption')"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="2.5">
            <input
              style="
                width: 272.22px;
                height: 40p;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 9px;
                text-align: center;
              "
              type="date"
              v-model="due_to"
            />
          </v-col>
          <v-col md="2.5">
            <v-text-field
              v-model="lesson_title"
              :label="$t('search by lesson title')"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="2.5">
            <v-autocomplete
              v-model="edu_class_id"
              outlined
              dense
              :items="eduClasses"
              :label="$t('search by educitional class')"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col md="2.5" class="d-flex justify-center align-center">
            <v-autocomplete
              v-model="subject_id"
              outlined
              dense
              :items="subjects"
              :label="$t('search by subject')"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <v-icon class="mr-2 mb-5" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        serverRoute="/homework"
        @re-fetch-paginated-data="fetchData($event)"
        :enableDelete="displayDeleteIcon"
        :flaggedItems="flaggedItemsData"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon color="green" @click="$router.push(`view-homework/${row.id}`)"
            >mdi-eye</v-icon
          >
          <app-edit-homework-modal
            @recordUpdated="fetchData"
            :recordData="row"
          ></app-edit-homework-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import editHomeworkModal from "./editHomeworkModal.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    appEditHomeworkModal: editHomeworkModal,
  },
  data() {
    return {
      edu_class_id: "",
      descirption: "",
      lesson_title: "",
      due_to_Dialog: false,
      due_to: "",
      subject_id: "",
      day: "",
      title: "",
      order: "",
      dialog: false,
      submitBtnLoading: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "homeworks/getTableOptions",
      teachers: "teachers/getTeachers",
      subjects: "subjects/getSubjects",
      eduClasses: "educationClass/getEduClasses",
    }),
  },
  methods: {
    ...mapActions({
      fetchHomeworks: "homeworks/fetchHomeworks",
      fetchLessons: "lessons/fetchLessons",
      fetchTeachers: "teachers/fetchTeachers",
      fetchSubjects: "subjects/fetchSubjects",
      fetchAllEduClasses: "educationClass/fetchAllEduClasses",
    }),

    fetchData(page) {
      this.dialog = false;
      this.fetchHomeworks({
        params: {
          page,
          edu_class_id: this.edu_class_id,
          descirption: this.descirption,
          subject_id: this.subject_id,
          due_to: this.due_to,
          day: this.day,
          order: this.order,
          title: this.title,
          lesson_title: this.lesson_title,
        },
      });
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
    this.fetchTeachers();
    this.fetchSubjects();
    this.fetchAllEduClasses();
  },
};
</script>

<style></style>
