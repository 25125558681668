<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }} {{ recordData.title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-text-field
              :label="$t('title')"
              v-model="editHomework.title"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <input
              style="
                width: 272.22px;
                height: 40p;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 9px;
                text-align: center;
              "
              type="date"
              v-model="editHomework.due_to"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
              :label="$t('description')"
              v-model="editHomework.description"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          depressed
          @click="editRecord"
          :loading="editBtnLoading"
          >{{ $t("edit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      due_to_Dialog: false,
      editHomework: {
        title: "",
        description: "",
        due_to: "",
        lesson_id: this.recordData.lesson.id,
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.editHomework.title = this.recordData.title;
      this.editHomework.description = this.recordData.description;
      this.editHomework.due_to = this.recordData.due_to;
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/homework/${this.recordData.id}`, {
          title: this.editHomework.title,
          description: this.editHomework.description,
          due_to: this.editHomework.due_to,
        });
        this.$Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
        console.log("re", err);
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  computed: {},
  created() {
    this.editHomework.title = this.recordData.title;
    this.editHomework.description = this.recordData.description;
    this.editHomework.due_to = this.recordData.due_to;
  },
};
</script>

<style></style>
